import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import AboveFooterSection from '../../components/AboveFooterSection'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

import archive from '../../resources/images/icons/archive.svg'
import database from '../../resources/images/icons/database.svg'
import cloud from '../../resources/images/icons/cloud.svg'
import cloud_download from '../../resources/images/icons/cloud-download.svg'

const deployment = () => {
  const data = useStaticQuery(graphql`
    query DeploymentSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "Product_Deployment" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="pt-5 pb-5 mt-5">
        <div className="container-1 ipad-margin-top">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-6 pt-0">
              <p className="title breadcrumb-text pt-4">
                <a
                  href="/architecture-and-integrations/"
                  className="title_text"
                >
                  PRODUCT
                </a>{' '}
                /<span className="title_text font-weight-bold">DEPLOYMENT</span>
              </p>
              <h1>Deployment Options</h1>
              <div className="pr-3">
                <p className="hero-description-dark pr-5">
                  Simple deployment options to allow you to be up and running in
                  minutes wherever your data infrastructure lives
                </p>
              </div>
              <div className="roll-button mt-2">
                <a className="bg-red text-white" href="/downloads/lenses/">
                  <span data-hover="Download now">Download now</span>
                </a>
              </div>
            </div>

            <div className="col-md-6 pt-0 ipad-margin-top ">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half"
                src="../../resources/images/vectors/undraw_building_blocks_n0nc.svg"
                placeholder="tracedSVG"
                alt="Lenses Deployment"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 ">
          <div className="d-flex flex-wrap  mt-5 mb-5">
            <div className="col-md-4 mt-5 mobile-text-center">
              <img
                src={archive}
                className="img-icon"
                aria-hidden="true"
                alt="Lenses Box"
              />
              <h2 className="paragraph-title pt-3 pb-1">All-in-one Box</h2>
              <p>
                Free all-in-one instance of Lenses with Kafka and eco-system
                components. Perfect for discovering Lenses, developing on and
                learning Kafka.{' '}
              </p>

              <div className="pt-2 pb-2">
                <a href="/downloads/lenses/" id="downloads-box">
                  <StaticImage
                    className="opct-6 customer-gray multi-icons-width"
                    src="../../resources/images/icons/docker.png"
                    placeholder="tracedSVG"
                    alt="docker"
                  />
                </a>
              </div>
              <div className="d-sm-block d-flex justify-content-center mobile-margin-top-half">
                <a href="/downloads/lenses/" className="bottom-0 primary-text">
                  Download Now <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>

            <div className="col-md-2 mt-5"></div>
            <div className="col-md-4 mt-5 mobile-text-center">
              <img
                src={database}
                className="img-icon"
                aria-hidden="true"
                alt="Kafka on-prem - vpn with Lenses"
              />
              <h2 className="paragraph-title pt-3 pb-1">On-Prem / On-VM </h2>
              <p>
                Lenses as a stateless JVM application running in a virtual
                machine or physical hardware, as a linux archive or container.
              </p>

              <div className="pt-3 pb-4 d-flex flex-wrap offset-sm-0 offset-4">
                <a
                  href="https://docs.lenses.io/4.3/installation/linux/"
                  id="downloads-linux"
                >
                  <StaticImage
                    className="opct-6 customer-gray mr-3 linux-icon-width"
                    src="../../resources/images/icons/linux.png"
                    placeholder="tracedSVG"
                    alt="linux"
                  />
                </a>

                <a
                  href="https://docs.lenses.io/4.3/installation/docker/"
                  id="downloads-docker"
                >
                  <StaticImage
                    className="opct-6 customer-gray mr-3 multi-icons-width"
                    src="../../resources/images/icons/docker.png"
                    placeholder="tracedSVG"
                    alt="docker"
                  />
                </a>

                <a
                  href="https://docs.lenses.io/4.3/installation/kubernetes/"
                  id="downloads-helm"
                >
                  <StaticImage
                    className="opct-6 customer-gray mr-3 multi-icons-width"
                    src="../../resources/images/icons/helm.png"
                    placeholder="tracedSVG"
                    alt="helm"
                  />
                </a>
              </div>

              <div className="d-sm-block d-flex justify-content-center mobile-margin-top-half">
                <a
                  href="https://docs.lenses.io/4.3/installation/"
                  className=" primary-text"
                >
                  Learn more <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 mobile-text-center">
        <div className="container-1 ">
          <div className="d-flex flex-wrap">
            <div className="col-md-4 mobile-text-center mt-5">
              <img
                src={cloud}
                className="img-icon"
                aria-hidden="true"
                alt="Lenses on Kafka Managed Services"
              />
              <h2 className="paragraph-title pt-3 pb-1">
                Kafka Managed Services
              </h2>
              <p>
                Connect Lenses to your Managed Kafka service wherever that may
                be
              </p>
              <div className="pb-2 pt-3"></div>
              <div className="d-sm-block d-flex justify-content-center mobile-margin-top-half">
                <a href="/contact-us/" className="bottom-0 primary-text">
                  Get in touch <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>

            <div className="col-md-4 offset-md-2 offset-0 mt-5">
              <img
                src={cloud_download}
                className="img-icon"
                aria-hidden="true"
                alt="Kafka on Clouds with Lenses"
              />
              <h2 className="paragraph-title  pt-3 pb-1">Public Cloud</h2>
              <p>
                No infrastructure to manage: Deploy via all major cloud
                providers
              </p>

              <div className="pt-3 pb-5 d-flex flex-wrap offset-sm-0 offset-4">
                <a href="/cloud/aws-msk/" id="downloads-cloud-amazon">
                  <StaticImage
                    className="opct-6 customer-gray mr-3 multi-icons-width"
                    src="../../resources/images/logos-clouds/amazon-web-services.svg"
                    placeholder="tracedSVG"
                    alt="Aws"
                  />
                </a>

                <a href="/cloud/azure/" id="downloads-cloud-azure">
                  <StaticImage
                    className="opct-6 customer-gray mr-3 multi-icons-width"
                    src="../../resources/images/logos-clouds/azure.svg"
                    placeholder="tracedSVG"
                    alt="Azure"
                  />
                </a>

                <a href="/cloud/google-cloud/" id="downloads-cloud-googlecloud">
                  <StaticImage
                    className="opct-6 customer-gray mr-3 multi-icons-width"
                    src="../../resources/images/logos-clouds/google-cloud.svg"
                    placeholder="tracedSVG"
                    alt="Google cloud"
                  />
                </a>

                <a href="/cloud/aiven/" id="downloads-cloud-aiven">
                  <StaticImage
                    className="opct-6 customer-gray multi-icons-width"
                    src="../../resources/images/cloud/aiven_crab.svg"
                    placeholder="tracedSVG"
                    alt="Aiven"
                  />
                </a>
              </div>
              <div className="d-sm-block d-flex justify-content-center mobile-margin-top-half">
                <a href="/cloud/" className="bottom-0 primary-text">
                  Get a trial key <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Ready to get started with Lenses?'}
        ctaText={'Try now for free'}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default deployment
